import { BaseNylasConnectorInterface } from '@/connector/connector-interface';
import { debug, error } from '@/utils/utils';
import { ComponentInterface, getElement } from '@stencil/core';
import { CombinedStoreStateKeys, EventEmitterEventType, ExtractEventEmitterKeys, ExtractEventEmitterProperties } from './types';
export interface RegisteredComponent<H extends ComponentInterface, B extends BaseNylasConnectorInterface, MailboxStores = string> {
  name: string;
  element: HTMLElement;
  getStoresToProp?: keyof H;
  storeToProps?: Map<string, keyof H>;
  stateToProps?: Map<MailboxStores, keyof H>;
  eventToProps?: Map<ExtractEventEmitterKeys<H>, (event: CustomEvent<EventEmitterEventType<H[ExtractEventEmitterKeys<H>]>>, nylasConnector: B) => Promise<void>>;
  localPropsToProp?: Map<string, keyof H>;
  authToProp?: keyof H;
  connectorToProp?: keyof H;
  registrationEventName?: string;
  unregistrationEventName?: string;
}

export function createComponentEvent<T extends ComponentInterface, B extends BaseNylasConnectorInterface>(
  instance: T,
  registeredComponent: RegisteredComponent<T, B>,
  eventName: string = 'registerComponent',
) {
  return new CustomEvent<RegisteredComponent<typeof instance, B>>(eventName, {
    bubbles: true,
    composed: true,
    detail: registeredComponent,
  });
}

export function unregisterComponentEvent<T extends ComponentInterface, B extends BaseNylasConnectorInterface>(
  instance: T,
  registeredComponent: RegisteredComponent<T, B>,
  eventName: string = 'unregisterComponent',
) {
  return new CustomEvent<RegisteredComponent<typeof instance, B>>(eventName, {
    bubbles: true,
    composed: true,
    detail: registeredComponent,
  });
}

export interface RegisterComponentConfig<H extends ComponentInterface, B extends BaseNylasConnectorInterface, MailboxStores>
  extends Omit<RegisteredComponent<H, B, MailboxStores>, 'eventToProps' | 'name' | 'element'> {
  name: string;
  eventToProps?: Partial<{
    [P in ExtractEventEmitterProperties<H> as P['key']]: (event: CustomEvent<P['eventType']>, nylasConnector: B) => Promise<void>;
  }>;
  fireRegisterEvent?: boolean;
}

export interface NylasComponentInterface extends ComponentInterface {
  authToProp?: keyof this;
  connectorToProp?: keyof this;
}

export function RegisterComponent<H extends NylasComponentInterface, B extends BaseNylasConnectorInterface, MailboxStores extends Record<string, any>>(
  config: RegisterComponentConfig<H, B, CombinedStoreStateKeys<keyof MailboxStores, MailboxStores>>,
) {
  return (target: H, propKey: string) => {
    const componentName = config.name;
    debug(`[${componentName}] Registering component ${componentName} being called on ${propKey}`);

    const origionalCallback = target.componentWillLoad;
    if (!origionalCallback) {
      error(`[${componentName}] componentWillLoad lifecycle method in ${componentName} missing. Required for RegisterComponent decorator.`);
      return;
    }

    const origionalConnectCallback = target.connectedCallback;
    if (!origionalConnectCallback) {
      error(`[${componentName}] connectedCallback lifecycle method in ${componentName} missing. Required for RegisterComponent decorator.`);
      return;
    }

    const origionalDisconnectCallback = target.disconnectedCallback;
    if (!origionalDisconnectCallback) {
      error(`[${componentName}] disconnectedCallback lifecycle method in ${componentName} missing. Required for RegisterComponent decorator.`);
      return;
    }

    // Detect a conflict if config.storeToProps and config.stateToProps are both defined
    // for overlapping prop keys
    if (config.storeToProps && config.stateToProps) {
      const storeToPropsKeys = Array.from(config.storeToProps.values());
      const stateToPropsKeys = Array.from(config.stateToProps.values());
      const intersection = storeToPropsKeys.filter(key => stateToPropsKeys.includes(key));
      if (intersection.length > 0) {
        error(
          `[${componentName}] Overlapping prop keys detected in ${componentName} for storeToProps and stateToProps. Make sure to only define them once. Overlapping keys: ${intersection.join(
            ', ',
          )}`,
        );
        return;
      }
    }

    // Convert the record of eventToProps to a map
    const eventToPropsMap = new Map<ExtractEventEmitterKeys<H>, (event: CustomEvent<EventEmitterEventType<H[ExtractEventEmitterKeys<H>]>>, nylasConnector: B) => Promise<void>>();
    if (config.eventToProps) {
      Object.keys(config.eventToProps).forEach(key => {
        eventToPropsMap.set(
          key as ExtractEventEmitterKeys<H>,
          config.eventToProps?.[key as ExtractEventEmitterKeys<H>] as (
            event: CustomEvent<EventEmitterEventType<H[ExtractEventEmitterKeys<H>]>>,
            nylasConnector: B,
          ) => Promise<void>,
        );
      });
    }

    target.connectedCallback = function () {
      debug(`[~${componentName}] connectedCallback called`);
      const origionalResult = origionalConnectCallback?.call(this);
      const host = getElement(this);

      // If the nylas id is already set, then we don't need to do anything
      if (host.dataset.nylasId) {
        return origionalResult;
      }

      Object.defineProperty(host, 'registerNylasComponent', {
        get() {
          return true;
        },
        enumerable: true,
      });
      Object.defineProperty(host, 'stateToProps', {
        get() {
          return config.stateToProps;
        },
        enumerable: true,
      });
      Object.defineProperty(host, 'getStoresToProp', {
        get() {
          return config.getStoresToProp;
        },
        enumerable: true,
      });
      Object.defineProperty(host, 'storeToProps', {
        get() {
          return config.storeToProps;
        },
        enumerable: true,
      });
      Object.defineProperty(host, 'eventToProps', {
        get() {
          return eventToPropsMap;
        },
        enumerable: true,
      });
      Object.defineProperty(host, 'authToProp', {
        get() {
          return config.authToProp;
        },
        enumerable: true,
      });
      Object.defineProperty(host, 'connectorToProp', {
        get() {
          return config.connectorToProp;
        },
        enumerable: true,
      });
      Object.defineProperty(host, 'localPropsToProp', {
        get() {
          return config.localPropsToProp;
        },
        enumerable: true,
      });
      return origionalResult;
    };

    if (config.fireRegisterEvent) {
      target.componentWillLoad = function () {
        const origionalResult = origionalCallback?.call(this);
        const host = getElement(this);

        // We use our host element to dispatch a registration event that is picked up by the
        // the nylas-provider component.
        const registeredComponent: RegisteredComponent<H, B> = {
          element: host,
          ...config,
          eventToProps: eventToPropsMap,
        };
        const event = createComponentEvent(target, registeredComponent, config.registrationEventName);
        if (host.dispatchEvent(event)) {
          debug(`[${componentName}] Component ${componentName} successfully fired registration event`);
        }

        return origionalResult;
      };

      target.disconnectedCallback = function () {
        const origionalResult = origionalDisconnectCallback?.call(this);
        const host = getElement(this);

        // We use our host element to dispatch a registration event that is picked up by the
        // the nylas-provider component.
        const registeredComponent: RegisteredComponent<H, B> = {
          element: host,
          ...config,
          eventToProps: eventToPropsMap,
        };
        const event = unregisterComponentEvent(target, registeredComponent, config.unregistrationEventName);
        if (host.dispatchEvent(event)) {
          debug(`[${componentName}] Component ${componentName} successfully fired unregistration event`);
        }

        return origionalResult;
      };
    }
  };
}

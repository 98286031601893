import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'arrow-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class ArrowIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      // Arrow icon svg
      <svg width={this.width} height={this.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12H19M12 5L19 12L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    );
  }
}

import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'calendar-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CalendarIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M14.3073 0.692383C15.9641 0.692383 17.3073 2.03553 17.3073 3.69238V14.3078C17.3073 15.9646 15.9641 17.3078 14.3073 17.3078H3.6919C2.03504 17.3078 0.691895 15.9646 0.691895 14.3078V3.69238C0.691895 2.03553 2.03504 0.692383 3.6919 0.692383H14.3073ZM15.9227 5.76931H2.07651V14.3078C2.07651 15.1999 2.79974 15.9232 3.6919 15.9232H14.3073C15.1994 15.9232 15.9227 15.1999 15.9227 14.3078V5.76931ZM5.07651 11.3078C5.71376 11.3078 6.23036 11.8244 6.23036 12.4616C6.23036 13.0989 5.71376 13.6155 5.07651 13.6155C4.43926 13.6155 3.92266 13.0989 3.92266 12.4616C3.92266 11.8244 4.43926 11.3078 5.07651 11.3078ZM8.99959 11.3078C9.63684 11.3078 10.1534 11.8244 10.1534 12.4616C10.1534 13.0989 9.63684 13.6155 8.99959 13.6155C8.36234 13.6155 7.84574 13.0989 7.84574 12.4616C7.84574 11.8244 8.36234 11.3078 8.99959 11.3078ZM5.07651 7.61546C5.71376 7.61546 6.23036 8.13206 6.23036 8.76931C6.23036 9.40656 5.71376 9.92315 5.07651 9.92315C4.43926 9.92315 3.92266 9.40656 3.92266 8.76931C3.92266 8.13206 4.43926 7.61546 5.07651 7.61546ZM8.99959 7.61546C9.63684 7.61546 10.1534 8.13206 10.1534 8.76931C10.1534 9.40656 9.63684 9.92315 8.99959 9.92315C8.36234 9.92315 7.84574 9.40656 7.84574 8.76931C7.84574 8.13206 8.36234 7.61546 8.99959 7.61546ZM12.9227 7.61546C13.5599 7.61546 14.0765 8.13206 14.0765 8.76931C14.0765 9.40656 13.5599 9.92315 12.9227 9.92315C12.2854 9.92315 11.7688 9.40656 11.7688 8.76931C11.7688 8.13206 12.2854 7.61546 12.9227 7.61546ZM14.3073 2.077H3.6919C2.79974 2.077 2.07651 2.80023 2.07651 3.69238V4.38469H15.9227V3.69238C15.9227 2.80023 15.1994 2.077 14.3073 2.077Z"
        />
      </svg>
    );
  }
}
